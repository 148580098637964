/**
 * Verlok - Vanilla-lazyload 16.1.0
 *
 *  https://github.com/verlok/vanilla-lazyload
 *
 */
(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
    typeof define === 'function' && define.amd ? define(factory) :
    (global = global || self, global.LazyLoad = factory());
  }(this, (function () { 'use strict';

    function _extends() {
      _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
          var source = arguments[i];

          for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
              target[key] = source[key];
            }
          }
        }

        return target;
      };

      return _extends.apply(this, arguments);
    }

    var runningOnBrowser = typeof window !== "undefined";
    var isBot = runningOnBrowser && !("onscroll" in window) || typeof navigator !== "undefined" && /(gle|ing|ro)bot|crawl|spider/i.test(navigator.userAgent);
    var supportsIntersectionObserver = runningOnBrowser && "IntersectionObserver" in window;
    var supportsClassList = runningOnBrowser && "classList" in document.createElement("p");
    var isHiDpi = runningOnBrowser && window.devicePixelRatio > 1;

    var defaultSettings = {
      elements_selector: "IMG",
      container: isBot || runningOnBrowser ? document : null,
      threshold: 300,
      thresholds: null,
      data_src: "src",
      data_srcset: "srcset",
      data_sizes: "sizes",
      data_bg: "bg",
      data_bg_hidpi: "bg-hidpi",
      data_bg_multi: "bg-multi",
      data_bg_multi_hidpi: "bg-multi-hidpi",
      data_poster: "poster",
      class_applied: "applied",
      class_loading: "loading",
      class_loaded: "loaded",
      class_error: "error",
      unobserve_completed: true,
      unobserve_entered: false,
      cancel_on_exit: false,
      callback_enter: null,
      callback_exit: null,
      callback_applied: null,
      callback_loading: null,
      callback_loaded: null,
      callback_error: null,
      callback_finish: null,
      callback_cancel: null,
      use_native: false
    };
    var getExtendedSettings = function getExtendedSettings(customSettings) {
      return _extends({}, defaultSettings, customSettings);
    };

    /* Creates instance and notifies it through the window element */
    var createInstance = function createInstance(classObj, options) {
      var event;
      var eventString = "LazyLoad::Initialized";
      var instance = new classObj(options);

      try {
        // Works in modern browsers
        event = new CustomEvent(eventString, {
          detail: {
            instance: instance
          }
        });
      } catch (err) {
        // Works in Internet Explorer (all versions)
        event = document.createEvent("CustomEvent");
        event.initCustomEvent(eventString, false, false, {
          instance: instance
        });
      }

      window.dispatchEvent(event);
    };
    /* Auto initialization of one or more instances of lazyload, depending on the
        options passed in (plain object or an array) */


    var autoInitialize = function autoInitialize(classObj, options) {
      if (!options) {
        return;
      }

      if (!options.length) {
        // Plain object
        createInstance(classObj, options);
      } else {
        // Array of objects
        for (var i = 0, optionsItem; optionsItem = options[i]; i += 1) {
          createInstance(classObj, optionsItem);
        }
      }
    };

    var statusLoading = "loading";
    var statusLoaded = "loaded";
    var statusApplied = "applied";
    var statusError = "error";
    var statusNative = "native";

    var dataPrefix = "data-";
    var statusDataName = "ll-status";
    var getData = function getData(element, attribute) {
      return element.getAttribute(dataPrefix + attribute);
    };
    var setData = function setData(element, attribute, value) {
      var attrName = dataPrefix + attribute;

      if (value === null) {
        element.removeAttribute(attrName);
        return;
      }

      element.setAttribute(attrName, value);
    };
    var getStatus = function getStatus(element) {
      return getData(element, statusDataName);
    };
    var setStatus = function setStatus(element, status) {
      return setData(element, statusDataName, status);
    };
    var resetStatus = function resetStatus(element) {
      return setStatus(element, null);
    };
    var hasEmptyStatus = function hasEmptyStatus(element) {
      return getStatus(element) === null;
    };
    var hasStatusLoading = function hasStatusLoading(element) {
      return getStatus(element) === statusLoading;
    };
    var hasStatusError = function hasStatusError(element) {
      return getStatus(element) === statusError;
    };
    var hasStatusNative = function hasStatusNative(element) {
      return getStatus(element) === statusNative;
    };
    var hadStartedLoading = function hadStartedLoading(element) {
      return !hasEmptyStatus(element);
    };

    var safeCallback = function safeCallback(callback, arg1, arg2, arg3) {
      if (!callback) {
        return;
      }

      if (arg3 !== undefined) {
        callback(arg1, arg2, arg3);
        return;
      }

      if (arg2 !== undefined) {
        callback(arg1, arg2);
        return;
      }

      callback(arg1);
    };

    var addClass = function addClass(element, className) {
      if (supportsClassList) {
        element.classList.add(className);
        return;
      }

      element.className += (element.className ? " " : "") + className;
    };
    var removeClass = function removeClass(element, className) {
      if (supportsClassList) {
        element.classList.remove(className);
        return;
      }

      element.className = element.className.replace(new RegExp("(^|\\s+)" + className + "(\\s+|$)"), " ").replace(/^\s+/, "").replace(/\s+$/, "");
    };

    var addTempImage = function addTempImage(element) {
      element.llTempImage = document.createElement("IMG");
    };
    var deleteTempImage = function deleteTempImage(element) {
      delete element.llTempImage;
    };
    var getTempImage = function getTempImage(element) {
      return element.llTempImage;
    };

    var unobserve = function unobserve(element, instance) {
      if (!instance) return;
      var observer = instance._observer;
      if (!observer) return;
      observer.unobserve(element);
    };
    var resetObserver = function resetObserver(observer) {
      observer.disconnect();
    };
    var unobserveIfRequired = function unobserveIfRequired(element, settings, instance) {
      if (settings.unobserve_entered) unobserve(element, instance);
    };

    var updateLoadingCount = function updateLoadingCount(instance, delta) {
      if (!instance) return;
      instance.loadingCount += delta;
    };
    var decreaseToLoadCount = function decreaseToLoadCount(instance) {
      if (!instance) return;
      instance.toLoadCount -= 1;
    };
    var setToLoadCount = function setToLoadCount(instance, value) {
      if (!instance) return;
      instance.toLoadCount = value;
    };
    var isSomethingLoading = function isSomethingLoading(instance) {
      return instance.loadingCount > 0;
    };
    var haveElementsToLoad = function haveElementsToLoad(instance) {
      return instance.toLoadCount > 0;
    };

    var getSourceTags = function getSourceTags(parentTag) {
      var sourceTags = [];

      for (var i = 0, childTag; childTag = parentTag.children[i]; i += 1) {
        if (childTag.tagName === "SOURCE") {
          sourceTags.push(childTag);
        }
      }

      return sourceTags;
    };
    var setAttributeIfValue = function setAttributeIfValue(element, attrName, value) {
      if (!value) {
        return;
      }

      element.setAttribute(attrName, value);
    };
    var resetAttribute = function resetAttribute(element, attrName) {
      element.removeAttribute(attrName);
    };
    var hasOriginalAttributes = function hasOriginalAttributes(element) {
      return !!element.llOriginalAttrs;
    };
    var saveOriginalImageAttributes = function saveOriginalImageAttributes(element) {
      if (hasOriginalAttributes(element)) {
        return;
      }

      var originalAttributes = {};
      originalAttributes["src"] = element.getAttribute("src");
      originalAttributes["srcset"] = element.getAttribute("srcset");
      originalAttributes["sizes"] = element.getAttribute("sizes");
      element.llOriginalAttrs = originalAttributes;
    };
    var restoreOriginalImageAttributes = function restoreOriginalImageAttributes(element) {
      if (!hasOriginalAttributes(element)) {
        return;
      }

      var originalAttributes = element.llOriginalAttrs;
      setAttributeIfValue(element, "src", originalAttributes["src"]);
      setAttributeIfValue(element, "srcset", originalAttributes["srcset"]);
      setAttributeIfValue(element, "sizes", originalAttributes["sizes"]);
    };
    var setImageAttributes = function setImageAttributes(element, settings) {
      setAttributeIfValue(element, "sizes", getData(element, settings.data_sizes));
      setAttributeIfValue(element, "srcset", getData(element, settings.data_srcset));
      setAttributeIfValue(element, "src", getData(element, settings.data_src));
    };
    var resetImageAttributes = function resetImageAttributes(element) {
      resetAttribute(element, "src");
      resetAttribute(element, "srcset");
      resetAttribute(element, "sizes");
    };
    var forEachPictureSource = function forEachPictureSource(element, fn) {
      var parent = element.parentNode;

      if (!parent || parent.tagName !== "PICTURE") {
        return;
      }

      var sourceTags = getSourceTags(parent);
      sourceTags.forEach(fn);
    };
    var forEachVideoSource = function forEachVideoSource(element, fn) {
      var sourceTags = getSourceTags(element);
      sourceTags.forEach(fn);
    };
    var restoreOriginalAttributesImg = function restoreOriginalAttributesImg(element) {
      forEachPictureSource(element, function (sourceTag) {
        restoreOriginalImageAttributes(sourceTag);
      });
      restoreOriginalImageAttributes(element);
    };
    var setSourcesImg = function setSourcesImg(element, settings) {
      forEachPictureSource(element, function (sourceTag) {
        saveOriginalImageAttributes(sourceTag);
        setImageAttributes(sourceTag, settings);
      });
      saveOriginalImageAttributes(element);
      setImageAttributes(element, settings);
    };
    var resetSourcesImg = function resetSourcesImg(element) {
      forEachPictureSource(element, function (sourceTag) {
        resetImageAttributes(sourceTag);
      });
      resetImageAttributes(element);
    };
    var setSourcesIframe = function setSourcesIframe(element, settings) {
      setAttributeIfValue(element, "src", getData(element, settings.data_src));
    };
    var setSourcesVideo = function setSourcesVideo(element, settings) {
      forEachVideoSource(element, function (sourceTag) {
        setAttributeIfValue(sourceTag, "src", getData(sourceTag, settings.data_src));
      });
      setAttributeIfValue(element, "poster", getData(element, settings.data_poster));
      setAttributeIfValue(element, "src", getData(element, settings.data_src));
      element.load();
    };
    var setSourcesFunctions = {
      IMG: setSourcesImg,
      IFRAME: setSourcesIframe,
      VIDEO: setSourcesVideo
    };
    var setBackground = function setBackground(element, settings, instance) {
      var bg1xValue = getData(element, settings.data_bg);
      var bgHiDpiValue = getData(element, settings.data_bg_hidpi);
      var bgDataValue = isHiDpi && bgHiDpiValue ? bgHiDpiValue : bg1xValue;
      if (!bgDataValue) return;
      element.style.backgroundImage = "url(\"".concat(bgDataValue, "\")");
      getTempImage(element).setAttribute("src", bgDataValue);
      manageLoading(element, settings, instance);
    }; // NOTE: THE TEMP IMAGE TRICK CANNOT BE DONE WITH data-multi-bg
    // BECAUSE INSIDE ITS VALUES MUST BE WRAPPED WITH URL() AND ONE OF THEM
    // COULD BE A GRADIENT BACKGROUND IMAGE

    var setMultiBackground = function setMultiBackground(element, settings, instance) {
      var bg1xValue = getData(element, settings.data_bg_multi);
      var bgHiDpiValue = getData(element, settings.data_bg_multi_hidpi);
      var bgDataValue = isHiDpi && bgHiDpiValue ? bgHiDpiValue : bg1xValue;

      if (!bgDataValue) {
        return;
      }

      element.style.backgroundImage = bgDataValue;
      manageApplied(element, settings, instance);
    };
    var setSources = function setSources(element, settings) {
      var setSourcesFunction = setSourcesFunctions[element.tagName];

      if (!setSourcesFunction) {
        return;
      }

      setSourcesFunction(element, settings);
    };
    var manageApplied = function manageApplied(element, settings, instance) {
      addClass(element, settings.class_applied);
      setStatus(element, statusApplied);
      removeDataMultiBackground(element, settings);

      if (settings.unobserve_completed) {
        // Unobserve now because we can't do it on load
        unobserve(element, settings);
      }

      safeCallback(settings.callback_applied, element, instance);
    };
    var manageLoading = function manageLoading(element, settings, instance) {
      updateLoadingCount(instance, +1);
      addClass(element, settings.class_loading);
      setStatus(element, statusLoading);
      safeCallback(settings.callback_loading, element, instance);
    }; // REMOVE DATA ATTRIBUTES --------------

    var removeDataImg = function removeDataImg(element, settings) {
      setData(element, settings.data_src, null);
      setData(element, settings.data_srcset, null);
      setData(element, settings.data_sizes, null);
      forEachPictureSource(element, function (sourceTag) {
        setData(sourceTag, settings.data_srcset, null);
        setData(sourceTag, settings.data_sizes, null);
      });
    };
    var removeDataIframe = function removeDataIframe(element, settings) {
      setData(element, settings.data_src, null);
    };
    var removeDataVideo = function removeDataVideo(element, settings) {
      setData(element, settings.data_src, null);
      setData(element, settings.data_poster, null);
      forEachVideoSource(element, function (sourceTag) {
        setData(sourceTag, settings.data_src, null);
      });
    };
    var removeDataFunctions = {
      IMG: removeDataImg,
      IFRAME: removeDataIframe,
      VIDEO: removeDataVideo
    };
    var removeDataBackground = function removeDataBackground(element, settings) {
      setData(element, settings.data_bg, null);
      setData(element, settings.data_bg_hidpi, null);
    };
    var removeDataMultiBackground = function removeDataMultiBackground(element, settings) {
      setData(element, settings.data_bg_multi, null);
      setData(element, settings.data_bg_multi_hidpi, null);
    };
    var removeDataAttributes = function removeDataAttributes(element, settings) {
      var removeDataFunction = removeDataFunctions[element.tagName];

      if (removeDataFunction) {
        removeDataFunction(element, settings);
        return;
      }

      removeDataBackground(element, settings);
    };

    var elementsWithLoadEvent = ["IMG", "IFRAME", "VIDEO"];
    var hasLoadEvent = function hasLoadEvent(element) {
      return elementsWithLoadEvent.indexOf(element.tagName) > -1;
    };
    var checkFinish = function checkFinish(settings, instance) {
      if (instance && !isSomethingLoading(instance) && !haveElementsToLoad(instance)) {
        safeCallback(settings.callback_finish, instance);
      }
    };
    var addEventListener = function addEventListener(element, eventName, handler) {
      element.addEventListener(eventName, handler);
      element.llEvLisnrs[eventName] = handler;
    };
    var removeEventListener = function removeEventListener(element, eventName, handler) {
      element.removeEventListener(eventName, handler);
    };
    var hasEventListeners = function hasEventListeners(element) {
      return !!element.llEvLisnrs;
    };
    var addEventListeners = function addEventListeners(element, loadHandler, errorHandler) {
      if (!hasEventListeners(element)) element.llEvLisnrs = {};
      var loadEventName = element.tagName === "VIDEO" ? "loadeddata" : "load";
      addEventListener(element, loadEventName, loadHandler);
      addEventListener(element, "error", errorHandler);
    };
    var removeEventListeners = function removeEventListeners(element) {
      if (!hasEventListeners(element)) {
        return;
      }

      var eventListeners = element.llEvLisnrs;

      for (var eventName in eventListeners) {
        var handler = eventListeners[eventName];
        removeEventListener(element, eventName, handler);
      }

      delete element.llEvLisnrs;
    };
    var doneHandler = function doneHandler(element, settings, instance) {
      deleteTempImage(element);
      updateLoadingCount(instance, -1);
      decreaseToLoadCount(instance);
      removeClass(element, settings.class_loading);

      if (settings.unobserve_completed) {
        unobserve(element, instance);
      }
    };
    var loadHandler = function loadHandler(event, element, settings, instance) {
      var goingNative = hasStatusNative(element);
      doneHandler(element, settings, instance);
      addClass(element, settings.class_loaded);
      setStatus(element, statusLoaded);
      removeDataAttributes(element, settings);
      safeCallback(settings.callback_loaded, element, instance);
      if (!goingNative) checkFinish(settings, instance);
    };
    var errorHandler = function errorHandler(event, element, settings, instance) {
      var goingNative = hasStatusNative(element);
      doneHandler(element, settings, instance);
      addClass(element, settings.class_error);
      setStatus(element, statusError);
      safeCallback(settings.callback_error, element, instance);
      if (!goingNative) checkFinish(settings, instance);
    };
    var addOneShotEventListeners = function addOneShotEventListeners(element, settings, instance) {
      var elementToListenTo = getTempImage(element) || element;

      if (hasEventListeners(elementToListenTo)) {
        // This happens when loading is retried twice
        return;
      }

      var _loadHandler = function _loadHandler(event) {
        loadHandler(event, element, settings, instance);
        removeEventListeners(elementToListenTo);
      };

      var _errorHandler = function _errorHandler(event) {
        errorHandler(event, element, settings, instance);
        removeEventListeners(elementToListenTo);
      };

      addEventListeners(elementToListenTo, _loadHandler, _errorHandler);
    };

    var loadBackground = function loadBackground(element, settings, instance) {
      addTempImage(element);
      addOneShotEventListeners(element, settings, instance);
      setBackground(element, settings, instance);
      setMultiBackground(element, settings, instance);
    };

    var loadRegular = function loadRegular(element, settings, instance) {
      addOneShotEventListeners(element, settings, instance);
      setSources(element, settings);
      manageLoading(element, settings, instance);
    };

    var load = function load(element, settings, instance) {
      if (hasLoadEvent(element)) {
        loadRegular(element, settings, instance);
      } else {
        loadBackground(element, settings, instance);
      }
    };
    var loadNative = function loadNative(element, settings, instance) {
      addOneShotEventListeners(element, settings, instance);
      setSources(element, settings);
      removeDataAttributes(element, settings);
      setStatus(element, statusNative);
    };

    var cancelLoadingIfRequired = function cancelLoadingIfRequired(element, entry, settings, instance) {
      if (!settings.cancel_on_exit) return;
      if (!hasStatusLoading(element)) return;
      if (element.tagName !== "IMG") return; //Works only on images

      removeEventListeners(element);
      resetSourcesImg(element);
      restoreOriginalAttributesImg(element);
      removeClass(element, settings.class_loading);
      updateLoadingCount(instance, -1);
      resetStatus(element);
      safeCallback(settings.callback_cancel, element, entry, instance);
    };

    var onEnter = function onEnter(element, entry, settings, instance) {
      safeCallback(settings.callback_enter, element, entry, instance);
      unobserveIfRequired(element, settings, instance);
      if (hadStartedLoading(element)) return; //Prevent loading it again

      load(element, settings, instance);
    };
    var onExit = function onExit(element, entry, settings, instance) {
      if (hasEmptyStatus(element)) return; //Ignore the first pass, at landing

      cancelLoadingIfRequired(element, entry, settings, instance);
      safeCallback(settings.callback_exit, element, entry, instance);
    };

    var tagsWithNativeLazy = ["IMG", "IFRAME"];
    var shouldUseNative = function shouldUseNative(settings) {
      return settings.use_native && "loading" in HTMLImageElement.prototype;
    };
    var loadAllNative = function loadAllNative(elements, settings, instance) {
      elements.forEach(function (element) {
        if (tagsWithNativeLazy.indexOf(element.tagName) === -1) {
          return;
        }

        element.setAttribute("loading", "lazy"); //TODO: Move inside the loadNative method

        loadNative(element, settings, instance);
      });
      setToLoadCount(instance, 0);
    };

    var isIntersecting = function isIntersecting(entry) {
      return entry.isIntersecting || entry.intersectionRatio > 0;
    };

    var getObserverSettings = function getObserverSettings(settings) {
      return {
        root: settings.container === document ? null : settings.container,
        rootMargin: settings.thresholds || settings.threshold + "px"
      };
    };

    var intersectionHandler = function intersectionHandler(entries, settings, instance) {
      entries.forEach(function (entry) {
        return isIntersecting(entry) ? onEnter(entry.target, entry, settings, instance) : onExit(entry.target, entry, settings, instance);
      });
    };

    var observeElements = function observeElements(observer, elements) {
      elements.forEach(function (element) {
        observer.observe(element);
      });
    };
    var updateObserver = function updateObserver(observer, elementsToObserve) {
      resetObserver(observer);
      observeElements(observer, elementsToObserve);
    };
    var setObserver = function setObserver(settings, instance) {
      if (!supportsIntersectionObserver || shouldUseNative(settings)) {
        return;
      }

      instance._observer = new IntersectionObserver(function (entries) {
        intersectionHandler(entries, settings, instance);
      }, getObserverSettings(settings));
    };

    var toArray = function toArray(nodeSet) {
      return Array.prototype.slice.call(nodeSet);
    };
    var queryElements = function queryElements(settings) {
      return settings.container.querySelectorAll(settings.elements_selector);
    };
    var excludeManagedElements = function excludeManagedElements(elements) {
      return toArray(elements).filter(hasEmptyStatus);
    };
    var hasError = function hasError(element) {
      return hasStatusError(element);
    };
    var filterErrorElements = function filterErrorElements(elements) {
      return toArray(elements).filter(hasError);
    };
    var getElementsToLoad = function getElementsToLoad(elements, settings) {
      return excludeManagedElements(elements || queryElements(settings));
    };

    var retryLazyLoad = function retryLazyLoad(settings, instance) {
      var errorElements = filterErrorElements(queryElements(settings));
      errorElements.forEach(function (element) {
        removeClass(element, settings.class_error);
        resetStatus(element);
      });
      instance.update();
    };
    var setOnlineCheck = function setOnlineCheck(settings, instance) {
      if (!runningOnBrowser) {
        return;
      }

      window.addEventListener("online", function () {
        retryLazyLoad(settings, instance);
      });
    };

    var LazyLoad = function LazyLoad(customSettings, elements) {
      var settings = getExtendedSettings(customSettings);
      this._settings = settings;
      this.loadingCount = 0;
      setObserver(settings, this);
      setOnlineCheck(settings, this);
      this.update(elements);
    };

    LazyLoad.prototype = {
      update: function update(givenNodeset) {
        var settings = this._settings;
        var elementsToLoad = getElementsToLoad(givenNodeset, settings);
        setToLoadCount(this, elementsToLoad.length);

        if (isBot || !supportsIntersectionObserver) {
          this.loadAll(elementsToLoad);
          return;
        }

        if (shouldUseNative(settings)) {
          loadAllNative(elementsToLoad, settings, this);
          return;
        }

        updateObserver(this._observer, elementsToLoad);
      },
      destroy: function destroy() {
        // Observer
        if (this._observer) {
          this._observer.disconnect();
        } // Clean custom attributes on elements


        queryElements(this._settings).forEach(function (element) {
          delete element.llOriginalAttrs;
        }); // Delete all internal props

        delete this._observer;
        delete this._settings;
        delete this.loadingCount;
        delete this.toLoadCount;
      },
      loadAll: function loadAll(elements) {
        var _this = this;

        var settings = this._settings;
        var elementsToLoad = getElementsToLoad(elements, settings);
        elementsToLoad.forEach(function (element) {
          load(element, settings, _this);
        });
      }
    };

    LazyLoad.load = function (element, customSettings) {
      var settings = getExtendedSettings(customSettings);
      load(element, settings);
    };

    LazyLoad.resetStatus = function (element) {
      resetStatus(element);
    }; // Automatic instances creation if required (useful for async script loading)


    if (runningOnBrowser) {
      autoInitialize(LazyLoad, window.lazyLoadOptions);
    }

    return LazyLoad;

  })));
